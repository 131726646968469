<template lang="pug">
section.homeAbout
  #about.homeAbout-titlebox
    h2 Your real estate specialist<br> in the Caribbean
  .homeAbout-logobox: img.svg-luxury(alt, src='@/assets/images/luxury.png')
  .homeAbout-descrbox
    p.
      One of the top agents serving the Caribbean's luxury markets,<br>
      <b> Ian hurdle brings more than 30 years of experience</b><br>
      in the real estate, construction, property management, and hospitality industries to his role as Founder and Director of The Agency Caribbean.
    p.
      The Agency is a full-service, luxury real estate brokerage and lifestyle company representing clients worldwide in a broad spectrum of classes, including residential, new development, resort real estate, residential leasing and luxury vacation rentals.
  .homeAbout-photobox: img.homeAbout-photo(alt, src='@/assets/images/ian.jpg')
</template>

<script>
  export default {
    components: {
      SvgComponent: () => import('@/components/SvgComponent'),
      DrawLine: () => import('@/components/DrawLine'),
    },
  };
</script>

<style lang="scss">
  .homeAbout {
    display: contents;
    &-titlebox {
      padding-top: 150px;
      padding-bottom: 50px;
      @include tablet {
        padding-top: 56px;
        padding-bottom: 32px;
        text-align: center;
      }
      @include phone {
        h2 {
          font-size: 24px;
          line-height: 29px;
        }
      }
    }
    &-logobox {
      padding-top: 150px;
      padding-bottom: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include tablet {
        padding-top: 20px;
        padding-bottom: 20px;
        .svg-luxury {
          transform-origin: center;
          transform: scale(0.8);
        }
      }
    }
    &-descrbox {
      font-size: 20px;
      line-height: 32px;
      padding-top: 130px;
      display: flex;
      flex-direction: column;
      padding-right: 80px;
      @include gap(80px, col);
      @include large {
        @include gap(50px, col);
      }
      @include middle {
        padding-top: 60px;
        font-size: 18px;
        padding-right: 60px;
      }

      @include tablet {
        padding-top: 50px;
        @include gap(24px, col);
        padding-right: 20px;
        padding-bottom: 190px;
        br {
          display: none;
        }
      }
      @include phone {
        padding-bottom: 250px;

        padding-top: 25px;
        padding-right: 0px;
      }

      p {
        @include tablet {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
    &-photobox {
      height: 600px;
      margin-bottom: 220px;
      @include middle {
        height: 480px;
        margin-bottom: 180px;
      }
      @include tablet {
        height: 320px;
        margin-bottom: 160px;
      }
      @include phone {
        height: 320px;
        margin-bottom: 0px;
      }
    }
    &-photo {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include phone {
        object-position: top;
      }
    }
  }
</style>
